import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import clsx from "clsx";
import { DeutscheBank, FriezeLogoNext } from "components/icons";
import { svgCss } from "components/Svg";
import { homeRouteConfig } from "features/Home/home.routes";
import { WithClassNameProps } from "interfaces";
import React from "react";
import { Link } from "react-router-dom";
import { customProps, zIndex } from "styles";
import classes from "./BaseHeader.module.scss";

export interface BaseHeaderProps {
  Controls: React.ComponentType<any>;
  className?: string;
  Decoration?: React.ComponentType<WithClassNameProps>;
  children?: React.ReactNode;
}

const headerSecondaryItemCss = css`
  display: flex;
  flex: 1 0 20%;
  &:last-child {
    justify-content: flex-end;
  }
`;

export const HeaderPlaceholder = styled.header`
  height: 80px;
  height: var(--header-height, 80px);
`;

const Root = styled.header`
  position: relative;
  z-index: ${zIndex.header};
  display: flex;
  align-items: center;

  height: 80px;
  height: var(--header-height, 80px);

  padding-left: var(${customProps.layoutPl});
  padding-right: var(${customProps.layoutPr});

  border-bottom: 1px solid black;
  background-color: #ffffff;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  flex-grow: 1;
  flex-shrink: 1;
`;

const HomeLink = styled(Link)`
  font-size: 24.3px;
`;

const DefaultDecoration = ({ className }: WithClassNameProps) => {
  return (
    <div className={className}>
      <a
        className={clsx(classes.link, classes.sponsorLink)}
        href="https://db.com/art"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Global lead partner Deutsche Bank"
      >
        <DeutscheBank width="167" className={classes.dbLogo} />
      </a>
    </div>
  );
};

const BaseHeader = ({ children, Controls, className, Decoration = DefaultDecoration }: BaseHeaderProps) => {
  return (
    <Root className={className}>
      <div className={headerSecondaryItemCss}>
        <Controls />
      </div>
      <Content>
        {!!children ? (
          children
        ) : (
          <HomeLink className={svgCss} to={homeRouteConfig.link}>
            <FriezeLogoNext height="20" />
          </HomeLink>
        )}
      </Content>
      <Decoration className={headerSecondaryItemCss} />
    </Root>
  );
};

export default BaseHeader;
