export const searchDebounceDelay = 300;
export const checkShowExhibitionDate = false;
export const selfOrigin = 'https://viewingroom.frieze.com';

export type BackgroundConfig = {
  width: number;
  height: number;
  rationPxCm: number;
  wallHeightPx: number;
  backgroundPositionX: number | null;
  topPadding: number;
  backgroundImage: string;
};

const backgroundConfigBase = {
  width: 5699,
  height: 3000
};

const baseBackgroundConfigNear = {
  ...backgroundConfigBase,
  wallHeightPx: 2070,
  topPadding: 0
};

export const backgroundConfigFar: Omit<BackgroundConfig, 'backgroundImage'> = {
  ...backgroundConfigBase,
  wallHeightPx: 2700,
  topPadding: -100,
  backgroundPositionX: -170,
  rationPxCm: 6
};

export const backgroundConfigNear: Omit<BackgroundConfig, 'backgroundImage'> = {
  ...baseBackgroundConfigNear,
  rationPxCm: 9.4,
  wallHeightPx: 2700,
  topPadding: -100,
  backgroundPositionX: -270
};

export const wsAnalyticsHost =
  process.env.NODE_ENV !== 'production'
    ? 'dev.viewingroom.frieze.com'
    : window.location.host;

export const paginationLimit = 20;
export const jwPlayerScript = 'https://cdn.jwplayer.com/libraries/yKWvOSbH.js';

// Dev - 2
// Staging - 2
// Production - 10
export const FMFairId = 2;

export const FLFairId = 1;

export const WedVipAccessId = 1;
export const ThurVipAccessId = 2;
export const PublicUserAccessId = 3;

export const editAccountInfoLink = 'https://www.frieze.com/account/edit';
export const vipProgrammingLink =
  'https://www.frieze.com/fairs/frieze-los-angeles/program';

export const wedVipProgrammeLink =
  'https://www.frieze.com/fairs/frieze-viewing-room/programme/vip-programme?location=in-london';

export const friezeWeekLink = 'https://www.frieze.com/frieze-week';

export const pdfViewrLink = '/pdf-viewer.html';
export const welcomeFriezeLink =
  'https://www.frieze.com/article/welcome-FVR-victoria-siddall-and-claudio-de-sanctis';

export const partnersSectionId = 10;
export const iosAppLink =
  'https://apps.apple.com/ru/app/frieze-viewing-room/id1500340570';
export const androidAppLink =
  'https://play.google.com/store/apps/details?id=com.frieze.viewingroom';

export const getAppBannerPath = '/a2e84063-a49c-4e3b-9fc7-8a09918213ef';

export const partnersLink = '/';

export const dbLink = 'https://www.deutsche-bank.de';

export const VorticGalleries = [
    {
      id: '1691', vorticLink: `https://embed-v4.vortic.io/#e=5046&bannerOnly=true&collapsed=true`
    },
    {
      id: '1661', vorticLink: `https://embed-v4.vortic.io/#e=4983&bannerOnly=true&collapsed=true`
    },
    {
      id: '1695', vorticLink: `https://embed-v4.vortic.io/#e=5000&bannerOnly=true&collapsed=true`
    },
    {
      id: '1696', vorticLink: `https://embed-v4.vortic.io/#e=5037&bannerOnly=true&collapsed=true`
    }
    ,
    {
      id: '1737', vorticLink: `https://embed-v4.vortic.io/#e=4982&bannerOnly=true&collapsed=true`
    },
    {
      id: '1721', vorticLink: `https://embed-v4.vortic.io/#e=4991&bannerOnly=true&collapsed=true`
    },
    {
      id: '1670', vorticLink: `https://embed-v4.vortic.io/#e=5036&bannerOnly=true&collapsed=true`
    },
    {
      id: '1770', vorticLink: `https://embed-v4.vortic.io/#e=5005&bannerOnly=true&collapsed=true`
    },
    {
      id: '1708', vorticLink: `https://embed-v4.vortic.io/#e=5031&bannerOnly=true&collapsed=true`
    },
    {
      id: '1761', vorticLink: `https://embed-v4.vortic.io/#e=5018&bannerOnly=true&collapsed=true`
    },
    {
      id: '1764', vorticLink: `https://embed-v4.vortic.io/#e=4912&bannerOnly=true&collapsed=true`
    },
    {
      id: '1677', vorticLink: `https://embed-v4.vortic.io/#e=5036&bannerOnly=true&collapsed=true`
    },
    { id: '1824', vorticLink: `https://embed-v4.vortic.io/#e=5408&bannerOnly=true&collapsed=true` },
    { id: '1804', vorticLink: `https://embed-v4.vortic.io/#e=5452&bannerOnly=true&collapsed=true` },
    { id: '1811', vorticLink: `https://embed-v4.vortic.io/#e=5446&bannerOnly=true&collapsed=true` },
    { id: '1787', vorticLink: `https://embed-v4.vortic.io/#e=5447&bannerOnly=true&collapsed=true` },
    { id: '1816', vorticLink: `https://embed-v4.vortic.io/#e=5456&bannerOnly=true&collapsed=true` }
  ]
;
