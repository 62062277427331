import {styled} from "@linaria/react";
import {spacerMixin} from "components/BaseSpacer";
import SvgDeutscheBank from "components/icons/DeutscheBank";
import Svg from "components/Svg";
import {
  frieze,
  friezeCookiePolicy,
  friezeLegals,
  friezePrivacyPolicy
} from "config/external-links";
import React from "react";
import {colors} from "styles";
import {down, up} from "styles/utils/breakpoints";
import em from "styles/utils/em";
import responsive from "styles/utils/responsive";
import useBreakpoint from "../../styles/utils/useBreakpoint";

type FooterProps = {
  className?: string;
};

const FooterRoot = styled.footer`
  z-index: 10;
  position: relative;

  background-color: #ffffff;

  .dark & {
    background-color: ${colors.dark};
  }

  ${down("lg")} {
    &:not(.disableMargin) {
      margin-top: 40px;
    }
  }
`;

const List = styled.ul`
  padding-top: 20px;
  display: flex;
  align-items: flex-start;
  min-height: 80px;

  ${spacerMixin}

  border-top: solid 1px rgb(0, 0, 0);

  .dark & {
    border-top-color: rgb(255, 255, 255, 0.2);
  }

  ${up("lg")} {
    padding-top: 0;

    align-items: center;
    border-top: solid 1px rgba(0, 0, 0, 0.29);

    .dark & {
      border-top-color: rgba(255, 255, 255, 0.29);
    }
  }
  
`;

const Text = styled.p`
  flex-shrink: 0;
  font-size: 12px;
  line-height: 1.67;
  letter-spacing: ${em("0.26px", "12px")};
  text-transform: uppercase;

  ${up("lg")} {
    font-size: 12px;
    line-height: 20px;

    letter-spacing: 0.280159px;
  }
`;

const Link = styled.a`
  text-decoration: none;
`;

const ListItem = styled.li`
  //display: none;
  flex-shrink: 0;

  &:first-child {
    display: initial;
  }

  & + & {
    ${responsive("margin-left", {
      base: "20px",
    })}
  }

  &.db {
    display: initial;
    margin-left: auto;
  }

  ${up("lg")} {
    display: initial;

    &.db {
      display: none;
    }
  }
`;

const DBSvg = styled(Svg)`
  font-size: 39px;
`;

const year = new Date().getFullYear();

const Footer = ({className}: FooterProps) => {
  const upLg = useBreakpoint(up('lg'))
  return (
    <FooterRoot className={className}>
      {upLg ?
        <List className="list">
          <ListItem>
            <Text>© Frieze {year}</Text>
          </ListItem>

          <ListItem>
            <Link
              href={friezePrivacyPolicy}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Text>Privacy Policy</Text>
            </Link>
          </ListItem>
          <ListItem>
            <Link
              href={friezeCookiePolicy}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Text>Cookie Policy</Text>
            </Link>
          </ListItem>
          <ListItem>
            <Link href={friezeLegals} target="_blank" rel="noopener noreferrer">
              <Text>Legals</Text>
            </Link>
          </ListItem>
          <ListItem>
            <Link href={frieze} target="_blank" rel="noopener noreferrer">
              <Text>Frieze.com</Text>
            </Link>
          </ListItem>
          <ListItem>
            <a style={{fontSize: 12, textTransform: 'uppercase', lineHeight: '20px'}} className="ot-sdk-show-settings">Cookies
              Settings</a> | <a style={{fontSize: 12, textTransform: "uppercase", lineHeight: '20px'}}
                                href="https://privacyportal-cdn.onetrust.com/dsarwebform/889c435d-64b4-46d8-ad05-06332fe1d097/c232b992-6f07-47de-9808-296303a99261.html?website=frieze.com">Do
            Not Sell My Personal Information</a>
          </ListItem>
          <ListItem className="db">
            <DBSvg>
              <SvgDeutscheBank height="40"/>
            </DBSvg>
          </ListItem>
        </List>
        :
        <>
          <List className="list">
            <ListItem>
              <a style={{fontSize: 12, textTransform: 'uppercase', lineHeight: '20px'}} className="ot-sdk-show-settings">Cookies
                Settings</a> | <a style={{fontSize: 12, textTransform: "uppercase", lineHeight: '20px'}}
                                  href="https://privacyportal-cdn.onetrust.com/dsarwebform/889c435d-64b4-46d8-ad05-06332fe1d097/c232b992-6f07-47de-9808-296303a99261.html?website=frieze.com">Do
              Not Sell My Personal Information</a>
            </ListItem>
          </List>
          <List className="list">
            <ListItem>
              <Text>© Frieze {year}</Text>
            </ListItem>
            <ListItem className="db">
              <DBSvg>
                <SvgDeutscheBank height="40"/>
              </DBSvg>
            </ListItem>
          </List>
        </>

      }
    </FooterRoot>
  );
};

export default Footer;
