import {styled} from "@linaria/react";
import clsx from "clsx";
import React from "react";
import {down, up} from "styles/utils/breakpoints";
import fluid from "styles/utils/fluid";
import responsive from "styles/utils/responsive";
import useBreakpoint from "styles/utils/useBreakpoint";
import NewsImage, {NewsImageWrapper} from "./components/NewsImage";
import {
  DontMissTitle,
  NewsDateText,
  NewsSectionTitle
} from "./components/NewsTypography";
import useDontMiss from "./hooks/useDontMiss";
import formatDate from "./utils/formatDate";

type Props = {
  className?: string;
};

const ListItem = styled.li`
  flex-shrink: 0;
  padding: 12px 0;
  overflow: hidden;
  ${down("lg")} {
    min-width: 0;

    &.full:last-of-type {
      margin-bottom: 20px;
    }

    &:not(.full) {
      border-top: 1px solid black;

      &:nth-last-child(-n + 2) {
        border-bottom: 1px solid black;
      }
    }
  }

  ${up("lg")} {
    border-bottom: 1px solid black;

    &:first-child {
      border-top: 1px solid black;
    }

    ${fluid(["padding-top", "padding-bottom"], {
      lg: "10px",
      xlg: "12px",
      xl: "15px",
    })}
  }
`;

const List = styled.ul`
  ${down("lg")} {
    margin-top: 7px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;

    & .full {
      grid-column: 1 / 3;
    }
  }
`;

const Spacer = styled.div`
  height: 18px;

  ${up("lg")} {
    display: none;
  }
`;

const Date = styled(NewsDateText)`
  margin-top: auto;
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  ${fluid("padding-left", {
    xs: "10px",
    md: "10px",
    lg: "5px",
    xlg: "6px",
    xl: "9px",
  })}
`;

const Link = styled.a`
  display: flex;
`;

function getFullItemsCount(items?: number) {
  if (!items) {
    return 0;
  }
  if (items < 4) {
    return items;
  }

  if (items % 2 === 0) {
    return 2;
  }
  return 3;
}

const Title = styled(DontMissTitle)`
  display: -webkit-box;
  text-transform: capitalize;
  font-size: 14px;
  
  text-overflow: ellipsis;
  white-space: initial;
  overflow: hidden;
  
  ${down('lg')} {
    max-height: 62px;
    font-size: 13px;
  }

  @supports (-webkit-line-clamp: 4) {
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
  
`;

const SectionTitle = styled(NewsSectionTitle)`
  ${responsive("line-height", {
    lg: "41px",
    xlg: "52px",
  })}
`;

const DontMiss = ({className}: Props) => {
  const {data} = useDontMiss();
  const upLg = useBreakpoint(up("lg"));
  const fullItemsCount = upLg ? 0 : getFullItemsCount(data?.length);

  return (
          <div className={className}>
            <SectionTitle>must read</SectionTitle>
            <List>
              {data?.map((item, index) => (
                      <React.Fragment key={item.id}>
                        {index === fullItemsCount && !upLg && <Spacer className="full"/>}
                        <ListItem
                                className={clsx({
                                  full: index < fullItemsCount,
                                })}
                                key={item.id}
                        >
                          <Link href={item.url} target="_blank" rel="noopener noreferrer">
                            <NewsImageWrapper
                                    className={clsx({
                                      full: index < fullItemsCount,
                                    })}
                            >
                              <NewsImage alt="" src={item.thumbnail}/>
                            </NewsImageWrapper>
                            <Text>
                              <Title
                                      className={clsx({
                                        full: index < fullItemsCount,
                                      })}
                                      as="h3"
                              >
                                {item.title}
                              </Title>
                              <Date>{formatDate(item.created)}</Date>
                            </Text>
                          </Link>
                        </ListItem>
                      </React.Fragment>
              ))}
            </List>
          </div>
  );
};

export default DontMiss;
